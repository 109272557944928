
<template>
  <div class="product-packages">
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-bordered table-striped mt-3">
            <thead>
              <tr>
                <th></th>
                <th class="text-center"  style="min-width: 100px">
                  Basic
                </th>
                <th class="text-center" style="min-width: 100px">
                  Standard
                </th>
                <th class="text-center" style="min-width: 100px">
                  Premium
                </th>
                <th class="text-center" style="min-width: 100px">
                  Platinum
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-nowrap" scope="row">Package Name <span class="text-danger">*</span></th>
                <td>
                  <div class="form-group mb-0">
                    <input type="text" v-validate="{required: true}" v-model="data.basic.name" name="basic-name" class="form-control" :class="{ 'is-invalid': errors.has('basic-name') }" placeholder="Enter Name" />
                    <span class="text-danger"  v-show="errors.has('basic-name')">{{ errors.first('basic-name') }}</span>
                  </div>
                </td>
                <td>
                  <div class="form-group mb-0" >
                    <input type="text" v-validate="{required: true}" v-model="data.standard.name" name="standard-name" class="form-control" :class="{ 'is-invalid': errors.has('standard-name') }" placeholder="Enter Name" />
                    <span class="text-danger"  v-show="errors.has('standard-name')">{{ errors.first('standard-name') }}</span>
                  </div>
                </td>
                <td>
                  <div class="form-group mb-0">
                    <input type="text" v-validate="{required: true}" v-model="data.premium.name" name="premium-name" class="form-control" :class="{ 'is-invalid': errors.has('premium-name') }" placeholder="Enter Name" />
                    <span class="text-danger"  v-show="errors.has('premium-name')">{{ errors.first('premium-name') }}</span>
                  </div>
                </td>
                <td>
                  <div class="form-group mb-0">
                    <input type="text" v-validate="{required: true}" v-model="data.platinum.name" name="platinum-name" class="form-control" :class="{ 'is-invalid': errors.has('platinum-name') }" placeholder="Enter Name" />
                    <span class="text-danger"  v-show="errors.has('platinum-name')">{{ errors.first('platinum-name') }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap" scope="row">Price ($) <span class="text-danger">*</span></th>
                <td>
                  <div class="form-group mb-0">
                    <input type="number" v-validate="{ required: true, regex: /^[0-9]+$/}" v-model="data.basic.price" class="form-control" name="basic-price" placeholder="Enter Price" :class="{ 'is-invalid': errors.has('basic-price') }" />
                    <span class="text-danger" v-show="errors.has('basic-price')">{{ errors.first('basic-price') }}</span>
                  </div>
                </td>
                <td>
                  <div class="form-group mb-0">
                    <input type="number" v-validate="{ required: true, regex: /^[0-9]+$/}" v-model="data.standard.price" class="form-control" name="standard-price" placeholder="Enter Price"  :class="{ 'is-invalid': errors.has('standard-price') }"/>
                    <span class="text-danger" v-show="errors.has('standard-price')">{{ errors.first('standard-price') }}</span>
                  </div>
                </td>
                <td>
                  <div class="form-group mb-0">
                    <input type="number" v-validate="{ required: true, regex: /^[0-9]+$/}" v-model="data.premium.price" class="form-control" name="premium-price" placeholder="Enter Price"  :class="{ 'is-invalid': errors.has('premium-price') }"/>
                    <span class="text-danger" v-show="errors.has('premium-price')">{{ errors.first('premium-price') }}</span>
                  </div>
                </td>
                <td>
                  <div class="form-group mb-0">
                    <input type="number" v-validate="{ required: true, regex: /^[0-9]+$/}" v-model="data.platinum.price" class="form-control" name="platinum-price" placeholder="Enter Price"  :class="{ 'is-invalid': errors.has('platinum-price') }"/>
                    <span class="text-danger" v-show="errors.has('platinum-price')">{{ errors.first('platinum-price') }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap" scope="row">Delivery time (hrs) <span class="text-danger">*</span></th>
                <td>
                 <div class="form-group mb-0">
                    <select v-model="data.basic.delivery_time" v-validate="{required: true}"
                      class="form-control" placeholder="--select day--" name="basic-delivery-time">
                      <option selected value="" >--select day--</option>
                      <option v-for="(dTime, dIndex) in deliveryDays" :key="dIndex"
                      :value="dTime.value" >{{dTime.text}}</option>
                    </select>
                    <span class="text-danger"  v-show="errors.has('basic-delivery-time')">{{ errors.first('basic-delivery-time') }}</span>
                  </div>
                </td>
                <td>
                 <div class="form-group mb-0">
                    <select v-model="data.standard.delivery_time" v-validate="{required: true}"
                      class="form-control" placeholder="--select day--" name="standard-delivery-time">
                      <option selected value="" >--select day--</option>
                      <option v-for="(dTime, dIndex) in deliveryDays" :key="dIndex"
                      :value="dTime.value" >{{dTime.text}}</option>
                    </select>
                    <span class="text-danger"  v-show="errors.has('standard-delivery-time')">{{ errors.first('standard-delivery-time') }}</span>
                  </div>
                </td>
                <td>
                 <div class="form-group mb-0">
                    <select v-model="data.premium.delivery_time" v-validate="{required: true}"
                      class="form-control" placeholder="--select day--" name="premium-delivery-time">
                      <option selected value="" >--select day--</option>
                      <option v-for="(dTime, dIndex) in deliveryDays" :key="dIndex"
                      :value="dTime.value" >{{dTime.text}}</option>
                    </select>
                    <span class="text-danger"  v-show="errors.has('premium-delivery-time')">{{ errors.first('premium-delivery-time') }}</span>
                  </div>
                </td>
                <td>
                 <div class="form-group mb-0">
                    <select v-model="data.platinum.delivery_time" v-validate="{required: true}"
                      class="form-control" placeholder="--select day--" name="platinum-delivery-time">
                      <option selected value="" >--select day--</option>
                      <option v-for="(dTime, dIndex) in deliveryDays" :key="dIndex"
                      :value="dTime.value" >{{dTime.text}}</option>
                    </select>
                    <span class="text-danger"  v-show="errors.has('platinum-delivery-time')">{{ errors.first('platinum-delivery-time') }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap" scope="row">Revision <span class="text-danger">*</span></th>
                <td>
                  <div class="form-group mb-0">
                    <input type="text" v-validate="{required: true}" v-model="data.basic.revisions" name="basic-revisions" class="form-control" :class="{ 'is-invalid': errors.has('basic-revisions')}" placeholder="e.g: 5 Days, Unlimited" />
                    <span class="text-danger"  v-show="errors.has('basic-revisions')">{{ errors.first('basic-revisions') }}</span>
                  </div>
                </td>
                <td>
                  <div class="form-group mb-0">
                    <input type="text" v-validate="{required: true}" v-model="data.standard.revisions" name="standard-revisions" class="form-control" :class="{ 'is-invalid': errors.has('standard-revisions')}" placeholder="e.g: 5 Days, Unlimited" />
                    <span class="text-danger"  v-show="errors.has('standard-revisions')">{{ errors.first('standard-revisions') }}</span>
                  </div>
                </td>
                <td>
                  <div class="form-group mb-0">
                    <input type="text" v-validate="{required: true}" v-model="data.premium.revisions" name="premium-revisions" class="form-control" :class="{ 'is-invalid': errors.has('premium-revisions')}" placeholder="e.g: 5 Days, Unlimited" />
                    <span class="text-danger"  v-show="errors.has('premium-revisions')">{{ errors.first('premium-revisions') }}</span>
                  </div>
                </td>
                <td>
                  <div class="form-group mb-0">
                    <input type="text" v-validate="{required: true}" v-model="data.platinum.revisions" name="platinum-revisions" class="form-control" :class="{ 'is-invalid': errors.has('platinum-revisions')}" placeholder="e.g: 5 Days, Unlimited" />
                    <span class="text-danger"  v-show="errors.has('platinum-revisions')">{{ errors.first('platinum-revisions') }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap" scope="row">Description</th>
                <td>
                  <div class="form-group mb-0">
                    <textarea v-model="data.basic.description" v-validate="'max:100'" class="form-control" rows="2" name="basic-desc"></textarea>
                    <span class="text-danger"  v-show="errors.has('basic-desc')">{{ errors.first('basic-desc') }}</span>
                  </div>
                </td>
                <td>
                  <div class="form-group mb-0">
                    <textarea v-model="data.standard.description" v-validate="'max:100'" class="form-control" rows="2" name="standard-desc"></textarea>
                    <span class="text-danger"  v-show="errors.has('standard-desc')">{{ errors.first('standard-desc') }}</span>
                  </div>
                </td>
                <td>
                  <div class="form-group mb-0">
                    <textarea v-model="data.premium.description" v-validate="'max:100'" class="form-control" rows="2" name="premium-desc"></textarea>
                    <span class="text-danger"  v-show="errors.has('premium-desc')">{{ errors.first('premium-desc') }}</span>
                  </div>
                </td>
                <td>
                  <div class="form-group mb-0">
                    <textarea v-model="data.platinum.description" v-validate="'max:100'" class="form-control" rows="2" name="platinum-desc"></textarea>
                    <span class="text-danger"  v-show="errors.has('platinum-desc')">{{ errors.first('platinum-desc') }}</span>
                  </div>
                </td>
              </tr>
              <template v-for="(attribute, index) in attributes">
                <tr v-if="attribute.type == 'check'" :key="index">
                  <th class="text-nowrap" scope="row">{{attribute.name}} </th>
                  <td class="text-center">
                    <b-form-checkbox v-model="data.basic.attributes[attribute.id]" :name="'checkbox-'+attribute.id" value="supported" unchecked-value="not_supported">
                    </b-form-checkbox>
                  </td>
                  <td class="text-center">
                    <b-form-checkbox v-model="data.standard.attributes[attribute.id]" :name="'checkbox-'+attribute.id" value="supported" unchecked-value="not_supported">
                    </b-form-checkbox>
                  </td>
                  <td class="text-center">
                    <b-form-checkbox v-model="data.premium.attributes[attribute.id]" :name="'checkbox-'+attribute.id" value="supported" unchecked-value="not_supported">
                    </b-form-checkbox>
                  </td>
                  <td class="text-center">
                    <b-form-checkbox v-model="data.platinum.attributes[attribute.id]" :name="'checkbox-'+attribute.id" value="supported" unchecked-value="not_supported">
                    </b-form-checkbox>
                  </td>
                </tr>

                <tr v-if="attribute.type == 'input'" :key="index">
                  <th class="text-nowrap" scope="row">{{attribute.name}} </th>
                  <td>
                    <div class="form-group mb-0">
                      <input type="text" v-model="data.basic.attributes[attribute.id]" class="form-control" placeholder="Enter value" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group mb-0">
                      <input type="text" v-model="data.standard.attributes[attribute.id]" class="form-control" placeholder="Enter value" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group mb-0">
                      <input type="text" v-model="data.premium.attributes[attribute.id]" class="form-control" placeholder="Enter value" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group mb-0">
                      <input type="text" v-model="data.platinum.attributes[attribute.id]" class="form-control" placeholder="Enter value" />
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
        </table>
      </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex mt-3 justify-content-end">
          <b-button variant="light" @click.prevent="resetData()" type="button" class="mr-2">Reset All</b-button>
          <b-button variant="primary" @click.prevent="submitPackage()" type="button">Submit Packages</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      data: {
        basic:{
          name: "",
          price: "",
          delivery_time: "",
          revisions: "",
          description:"",
          attributes:{}
        },
        standard: {
          name: "",
          price: "",
          delivery_time: "",
          revisions: "",
          description: "",
          attributes:{}
        },
        premium:{
          name: "",
          price: "",
          delivery_time: "",
          revisions: "",
          description: "",
          attributes:{}
        },
        platinum:{
          name: "",
          price: "",
          delivery_time: "",
          revisions: "",
          description: "",
          attributes:{}
        },
      },
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product;
    },
    packages(){
      return this.product.product_packages
    },
    attributes(){
      return this.product.attributes
    },
    deliveryDays(){
      let days = [{value: 24, text: "1 day"}];
      for (let step = 2; step < 101; step++) {
        days.push({value: step*24, text: `${step} days`});
      }
      return days;
    }
  },
  methods: {
    submitPackage() {
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form not valid"); return }
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/packages/${this.product.id}/create`,formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue")
          if(response.data.success){
            this.$store.commit("productList/SET_PACKAGES", response.data.data.packages)
          }
        })
      })
    },
    parsePackages(){
      var packages = JSON.parse(JSON.stringify(this.packages))
      if(packages && packages.length){
        packages.forEach((p)=> {
          if(["basic","standard","premium","platinum"].includes(p.pack_id)){
            this.data[p.pack_id] = p;
          }
        });
      }
    },
    resetData(){
      this.data = {
        basic:{
          name: "",
          price: "",
          delivery_time: "",
          revisions: "",
          description:"",
          attributes:{}
        },
        standard: {
          name: "",
          price: "",
          delivery_time: "",
          revisions: "",
          description: "",
          attributes:{}
        },
        premium:{
          name: "",
          price: "",
          delivery_time: "",
          revisions: "",
          description: "",
          attributes:{}
        },
        platinum:{
          name: "",
          price: "",
          delivery_time: "",
          revisions: "",
          description: "",
          attributes:{}
        },
      }
      this.$validator.reset();
    },
  },
  created(){
    this.parsePackages();
  }
}
</script>
